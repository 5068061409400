@import "./../styles/variables.scss";

.no-email-wrap {
  background-image: url("../assets/no-mail.svg");
  min-height: 13rem;
  background-position: right bottom;
  background-size: 12rem;
  background-repeat: no-repeat;
}

/* Radio Button Styles */
label>input[type="radio"] {
  display: none;

  &+*::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.3rem;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: gray;
  }

  &:checked+*::before {
    background: radial-gradient(#5b125c 0%,
        #5b125c 40%,
        transparent 50%,
        transparent);
    border-color: #5b125c;
  }
}

.column-type-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

/* Chrome, Safari, Edge, Opera */
input.incrementor-input::-webkit-outer-spin-button,
input.incrementor-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.incrementor-input[type="number"] {
  -moz-appearance: textfield;
}

/* For Chrome or Safari */
progress::-webkit-progress-bar {
  background-color: #eeeeee;
}

progress::-webkit-progress-value {
  background-color: blue !important;
}

/* For Firefox */
progress {
  background-color: #eee;
}

progress::-moz-progress-bar {
  background-color: blue !important;
}

/* For IE10 */
progress {
  background-color: #eee;
}

td {
  border-left: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}

table {
  border-spacing: 0;
  table-layout: fixed;
}

thead,
th {
  border-bottom: 1px solid #dcdcdc;
}

td:last-child {
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}

/* temp styles for webhook fields selector buttons */
.webhook-field-selector img {
  height: 11px;
}

.webhook-field-selector~#dk-list-picker .hide-scroll-bar {
  max-height: 120px !important;
}

.data-source-popup .popup-window,
.column-webhook-popup .popup-window,
.fb-field-mapper .popup-window,
.fb-page-form-container .popup-window {
  overflow-y: visible;
}

/* temp styles for Split action popup button */
.split-email-config-button img {
  height: 11px;
  padding-top: 4px;
}

@media screen and (max-width: $mobilebp) {
  div.banner-holder {
    display: none;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* Style the tab */
.tab {
  overflow-x: auto;
  min-width: 100%;
  width: 100px;
}

/* Style the buttons inside the tab */
.tab button {
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  width: 100%;
  background-color: white;
  white-space: nowrap;
}

/* Change background color of buttons on hover */
.tab button:hover {
  color: #411c40;
}

/* Create an active/current tablink class */
.tab button.active {
  color: #411c40;
  border-bottom: 1px solid #411c40;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.email-button-wrapper .action-buttons {
  display: none;
  padding: 0;
}

.email-button-wrapper:hover .action-buttons {
  display: flex;
  padding: 0;
}

.full-screen-width {
  position: absolute;
  top: 0;
  right: 0;
}

.border-b-gray {
  border-bottom: 1px solid #dcdcdc;
}

.quote-memo-textarea {
  width: 100%;
  height: 130px;
  font-family: inherit;
  padding: 10px;
  outline: none;
  border-radius: 1px;
  background-color: #fafafa;
  border: 1px dashed #c8c8c8;
}

.overflow-visible {
  overflow: visible;
}

/* for chat-bubble overlap with ticket controls in contact/deal/account detail pages */
.detail-page-email-composer .log-email-container {
  padding-bottom: 70px;
}

.body-message {
  table div {
    img:first-child {
      width: auto;
      max-width: 100%;
    }
  }

  div {
    font-size: 13px !important;
    width: 100%;

    img:first-child {
      width: inherit;
      max-width: 100%;
    }
  }
}

.ticket-contact-icon {
  min-width: 30px;
}

div.deal-kanban-search-bar {
  div {
    margin: 0;
  }
}

.quote-add-contact-btn {
  outline: none;
  background-color: #d2e4f9;
  color: #1664d7;
  border: 1px dashed #1664d7;
}

.contact-required {
  background-color: #ffd4d4;
  color: #cb1a26;
  border-color: #cb1a26;
}

.overflow-y-auto {
  overflow-y: auto;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.opacity-0-5 {
  opacity: 0.5;
}

.filter-red-active::after {
  content: "\A";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #cb1a26;
  position: absolute;
  top: 0;
  right: 95px;
}

.owner-drop-down text {
  padding-right: 20px;
}

.owner-drop-down:after {
  color: black;
  border-right: 1px solid currentcolor;
  border-bottom: 1px solid currentcolor;
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
}

.owner-drop-down:after {
  right: 151px;
  top: 11px;
  transform: rotate(43deg);
}

.customReport .actionBtn {
  display: none;
}

.customReport:hover .actionBtn {
  display: flex;
}

.hide-chat-bubble {
  #deskera-chat-root {
    display: none;
  }
}

.email-popup {
  div {
    .textField {
      &:focus {
        border-color: none !important;
      }
    }
  }
}

.ticket-row .ticket-delete-icon {
  display: none;
}

.ticket-row:hover .ticket-delete-icon {
  display: flex;
}

.account-icon-wrapper {
  visibility: hidden;
}

.account-card:hover {
  .account-icon-wrapper {
    visibility: visible;
  }
}

/* ********** ATTACHMENT STYLES ********** */
.attachment-preview-popup {
  .transparent-background {
    z-index: 999999;
  }

  .popup-body {
    width: 100%;
    height: 100%;
  }
}

.attachment-wrapper {
  .attachment-title {
    display: none;
  }

  &:hover {
    .attachment-title {
      display: flex;
    }
  }
}

.note-wrapper {
  .action-wrapper {
    visibility: hidden;
  }

  &:hover {
    .action-wrapper {
      visibility: visible;
    }
  }
}

.wa-template-selector text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (min-width: $largebp) {
  .book-demo-holder {
    width: 70%;
    max-height: 600;
    height: auto;

    .demo-date-time {
      margin-top: 60px;
    }
  }
}

@media (max-width: $mobilebp) {
  .book-demo-holder {
    width: 100%;
    height: auto;

    .demo-date-time {
      margin-top: 5px;
    }
  }
}

@media (min-width: $largebp) {
  .success-demo-wrapper {
    width: 35%;
    height: 90%;
  }
}

.website-link text {
  white-space: normal !important;
}

.pricing-card-mobile-wrapper,
.plan-wrapper,
.price-card-mobile-footer {
  max-width: 65vw;
  display: none !important;
}

.pricing-card-wrapper,
.price-card-footer {
  max-width: 90vw !important;
}

@media only screen and (max-width: 800px) {
  .plan-wrapper {
    max-width: 70vw;
    display: flex !important;
    flex-wrap: wrap;
  }

  .pricing-card-mobile-wrapper,
  .price-card-mobile-footer {
    max-width: 80vw;
    display: flex !important;
  }

  .pricing-card-wrapper,
  .price-card-footer,
  .product-button-group,
  .plan-button-group {
    display: none !important;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1024px) {
  .plan-wrapper {
    max-width: 65vw;
    display: flex !important;
    flex-wrap: nowrap;
  }

  .pricing-card-mobile-wrapper,
  .price-card-mobile-footer {
    max-width: 65vw;
    display: block !important;
  }

  .pricing-card-wrapper,
  .price-card-footer {
    max-width: 65vw;
    display: none !important;
  }
}

.export-button.cursor-not-allowed {
  cursor: not-allowed !important;
}

.width-50 {
  width: 50% !important;
}

.custom-number-compact-input:hover .disable-input-mode {
  opacity: 0.4 !important;
}

.border-transparent {
  border-color: transparent;
}

.border-gray-300:hover {
  border-color: rgba(209, 213, 219, 1);
}

.top-10 {
  top: 2.5rem;
}

.right-1 {
  right: 0.25rem;
}

.right-2 {
  right: 0.5rem;
}

.top-25 {
  top: 0.625rem;
}

.bottom-25 {
  bottom: 0.625rem;
}

.contact-section {
  width: 70%;
}

.date-section {
  width: 30%;
}

.contact-section-column,
.quote-memo-wrapper {
  width: 50%;
}

.quote-summary-section {
  width: 29%;
}

.invoice-summary-section {
  .summary-section {
    width: 49%;
  }
}

.notification-popup {
  top: 36px;
  right: 0;
  width: 330px;
  z-index: 9999;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .contact-section-wrapper {
    flex-direction: column;
  }

  .contact-section,
  .date-section {
    width: 100%;
  }

  .quote-memo-textarea {
    width: 90%;
  }

  .contact-section-column {
    width: 100% !important;
    padding-bottom: 10px;
  }

  .quote-summary-section {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #dk-list-picker-2 {
    right: inherit !important;
  }

  .quote-memo-section {
    flex-direction: column-reverse;
  }

  .invoice-summary-section {
    flex-direction: column;

    .summary-section {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .quote-memo-wrapper {
    width: 100%;
  }

  .notification-popup {
    top: 41px;
    right: -53px;
    width: 287px;
  }

  .dk-data-grid-container>.row.justify-content-between>div>div.row-reverse.position-relative {
    flex-wrap: wrap-reverse;
  }
}

.product-selection-popup-wrapper>div>div #grid-filter-popup {
  right: 130px;
}

.setting-multi-currency-dropdown {
  .hide-scroll-bar {
    max-height: 175px !important;
  }
}

.deal-name-tooltip {
  left: 800px !important;
}

.position-unset {
  position: unset !important;
}

.waiting-for-customer {
  color: #ff2e94;
  border: 1px solid #ff2e94;
}

.in-progress {
  color: #e6653e;
  border: 1px solid #e6653e;
}

.custom-date-range-picker {
  background-color: #ffffff;
}

.cf-p-b {
  padding-bottom: 5px;
}

.cf-options {
  .custom-field-options-edit-delete {
    display: none;
  }
}

.cf-options:hover,
.input-field-cf:hover {
  .custom-field-options-edit-delete {
    display: flex;
  }
}

.ticket-filter .dkinput-header-section {
  width: 89px;
}

.price-book-row {
  .price-book-delete-button {
    visibility: hidden;
  }
}

.price-book-row:hover {
  .price-book-delete-button {
    visibility: visible;
  }
}

.support-ticket-input {
  #dk-list-picker-2 {
    .hide-scroll-bar {
      scrollbar-width: thin !important;
      overflow-y: auto !important;
    }
  }
}

.subject-body {
  p {
    margin: 0;
    padding: 0;
  }
}

// .ticket-email-body {©
.dots {
  width: 15px !important;
  margin-top: 6px;
  padding-right: 3px;
  padding-left: 4px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.email-body {
  display: none;

  &.active {
    display: block;
  }
}

// }

.ticket-multi-count {
  .email-no-count {
    display: block;
  }

  .email-arrows {
    display: none;
  }
}

.ticket-multi-count:hover {
  .email-no-count {
    display: none;
  }

  .email-arrows {
    display: flex;
  }
}

.icon-height-90 {
  height: 90px;
  margin-top: 0px !important;
}

.approval-deal-name>img {
  visibility: hidden;
}

.approval-deal-name:hover>img {
  visibility: visible;
}

.width-300 {
  width: 300px;
  max-width: 300px;
}

.border-m-b-white {
  border-bottom: 1px solid #e0e0e0;
}

.email-composer-wrapper {
  .dkinput-textfield-height {
    border: none !important;
  }

  .bg-dk-input {
    background-color: transparent;
  }
}

.quote-list {
  .dk-filter-popup {
    right: 0px !important
  }
}

.border-radius-right,
.border-radius-right-list {
  border-radius: 0px 4px 4px 0px;
}

.border-radius-left,
.border-radius-left-list {
  border-radius: 4px 0px 0px 4px;
}

.border-radius-calendar-type {
  border-radius: 4px;
}

.border-radius-right-list:before,
.border-radius-list::before {
  content: "";
  position: absolute;
  height: 0px;
  width: 0px;
  left: -8px;
  border-width: 12px;
  border-color: transparent inherit transparent transparent;
  border-style: solid;
}

.border-radius-left-list:after,
.border-radius-list:after {
  content: "";
  position: absolute;
  height: 0px;
  width: 0px;
  left: 233px;
  border-width: 12px;
  border-color: transparent transparent transparent inherit;
  border-style: solid;
}

.data-grid-badge-color-1.border-radius-list::before,
.data-grid-badge-color-1.border-radius-right-list::before {
  border-color: transparent #e6e6e5 transparent transparent;
}

.data-grid-badge-color-1.border-radius-list::after,
.data-grid-badge-color-1.border-radius-left-list::after {
  border-color: transparent transparent transparent #e6e6e5;
}

.data-grid-badge-color-2.border-radius-list::before,
.data-grid-badge-color-2.border-radius-right-list::before {
  border-color: transparent #d7d7d6 transparent transparent;
}

.data-grid-badge-color-2.border-radius-list::after,
.data-grid-badge-color-2.border-radius-left-list::after {
  border-color: transparent transparent transparent #d7d7d6;
}

.data-grid-badge-color-5.border-radius-list::before,
.data-grid-badge-color-5.border-radius-right-list::before {
  border-color: transparent #f0e1c8 transparent transparent;
}

.data-grid-badge-color-5.border-radius-list::after,
.data-grid-badge-color-5.border-radius-left-list::after {
  border-color: transparent transparent transparent #f0e1c8;
}


.data-grid-badge-color-6.border-radius-list::before,
.data-grid-badge-color-6.border-radius-right-list::before {
  border-color: transparent #d5e6e2 transparent transparent;
}

.data-grid-badge-color-6.border-radius-list::after,
.data-grid-badge-color-6.border-radius-left-list::after {
  border-color: transparent transparent transparent #d5e6e2;
}

.data-grid-badge-color-7.border-radius-list::before,
.data-grid-badge-color-7.border-radius-right-list::before {
  border-color: transparent #d2e6fa transparent transparent;
}

.data-grid-badge-color-7.border-radius-list::after,
.data-grid-badge-color-7.border-radius-left-list::after {
  border-color: transparent transparent transparent #d2e6fa;
}


.data-grid-badge-color-8.border-radius-list::before,
.data-grid-badge-color-8.border-radius-right-list::before {
  border-color: transparent #e1d5fa transparent transparent;
}

.data-grid-badge-color-8.border-radius-list::after,
.data-grid-badge-color-8.border-radius-left-list::after {
  border-color: transparent transparent transparent #e1d5fa;
}


.data-grid-badge-color-10.border-radius-list::before,
.data-grid-badge-color-10.border-radius-right-list::before {
  border-color: transparent #ffd4d4 transparent transparent;
}

.data-grid-badge-color-10.border-radius-list::after,
.data-grid-badge-color-10.border-radius-left-list::after {
  border-color: transparent transparent transparent #ffd4d4;
}

.email-requestor-criteria {
  .dk-filter-popup {
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: auto;
    height: fit-content;
  }
}

.kanban-filter-container .dk-filter-popup #dk-list-picker {
  min-width: 135px;
}

#ticket-automation {
  .dk-filter-popup {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 20, .05);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 50%;
    max-height: 90%;
    max-width: 570px;
    overflow-y: scroll;
    padding: 10px;
    position: fixed;
    scrollbar-width: none;
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 90%;
    z-index: 9998;
  }
}

.logical-operator {
  #dk-list-picker-2 {
    min-width: 100px !important;
  }
}

.visibility-input {
  .dkinput-header-section {
    width: 40px
  }

  .dkinput-value-section {
    width: 150px
  }
}

.account-custom-number {
  width: 100% !important;
  top: 2.3rem !important
}

.currency-dropdown-code {
  text-align: left;
}

.dynamic-form-template {
  .phone-number-input-container {

    .phone-number-input {
      .text-red {
        left: -75px
      }
    }
  }
}